import PropTypes from 'prop-types';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AdminAdCampaignOverviewModal } from '../Admin/Ad/Campaign/OverviewModal';
import { AdminAnalyticsChannelOverviewModal } from '../Admin/Analytics/Channels/OverviewModal';
import { AnalyticsEngagementOverviewVideoModal } from './AnalyticsAndAdvertising/Analytics/Engagement/Overview/VideoModal';
import { AnalyticsAndAdvertisingModal } from './AnalyticsAndAdvertising/Modal';

import { UserSettingsContext, UserSettingsModal } from './Context';
import { InfoCenterModal } from './InfoCenterModal';
import { LibraryModal } from './LibraryModal/LibraryModal';
import { MembershipModal } from './MembershipModal';
import { MyAccountModal } from './MyAccountModal';
import { SurveyVoteTemplateModal } from './SurveyVoteTemplateModal';
import { PaginationProvider } from '../Pagination/Provider';
import { ListSortingProvider } from '../ListSorting/Provider';
import { LibraryModalMode } from './LibraryModal/LibraryModalMode';
import { OrderBundleType } from '../../views/Checkout/Paypal/Order/Button';
import { MembershipModalTab } from './MembershipModalTab';

export const UserSettingsProvider = ({ children }) => {
	const [modalOpen, setModalOpen] = useState();
	const [activeTab, setActiveTab] = useState();
	const [isSharedBy, setIsSharedBy] = useState();
	const [callback, setCallback] = useState();
	const [analyticsVod, setAnalyticsVod] = useState();
	const [analyticsChannel, setAnalyticsChannel] = useState();
	const [analyticsAndAdModalOpen, setAnalyticsAndAdModalOpen] = useState(false);
	const [adCampaignId, setAdCampaignId] = useState();
	const [overModalOpen, setOverModalOpen] = useState();
	const [overCallback, setOverCallback] = useState();
	const [analyticsAndAdModalTab, setAnalyticsAndAdModalTab] = useState(false);
	const [modalInitialValue, setModalInitialValue] = useState();
	const [advertisingActiveTab, setAdvertisingActiveTab] = useState(null);
	const [modalActiveMode, setModalActiveMode] = useState(LibraryModalMode.MY_LIBRARY);
	const [orderBundleType, setOrderBundleType] = useState(OrderBundleType.PI_POINTS);

	const [searchParams, setSearchParams] = useSearchParams();

	const closeModal = useCallback(() => {
		setModalOpen(undefined);
		setActiveTab(undefined);
		setIsSharedBy(undefined);
	}, []);

	const openModal = useCallback((modalType, tab, sharedBy, cb, initialValue, activeMode) => {
		setModalInitialValue(initialValue);
		setModalOpen(modalType);
		setIsSharedBy(sharedBy);
		setCallback(() => cb);

		if (tab) {
			setActiveTab(tab);

			if (tab === MembershipModalTab.BUY_POINTS) {
				setOrderBundleType(OrderBundleType.PI_POINTS);
			} else if (tab === MembershipModalTab.BUY_AD_CREDITS) {
				setOrderBundleType(OrderBundleType.AD_CREDITS);
			}
		}
		if (activeMode) {
			setModalActiveMode(activeMode);
		}
	}, []);

	const clearUserSettingsSearchParams = useCallback(() => {
		setSearchParams((prev) => {
			prev.delete('userSettings');
			prev.delete('userSettingsTab');
			return prev;
		}, { replace: true });
	}, [setSearchParams]);

	const userSettingsModal = searchParams.get('userSettings');
	const userSettingsModalTab = searchParams.get('userSettingsTab');

	useEffect(() => {
		if (userSettingsModal) {
			openModal(userSettingsModal, userSettingsModalTab);
			clearUserSettingsSearchParams();
		}
	}, [clearUserSettingsSearchParams, openModal, userSettingsModal, userSettingsModalTab]);

	const openAnalyticsAndAdModal = useCallback((tab, advertisingTab = null) => {
		setAnalyticsAndAdModalOpen(true);

		if (tab) {
			setAnalyticsAndAdModalTab(tab);
		}
		if (advertisingTab) {
			setAdvertisingActiveTab(advertisingTab);
		}
	}, []);

	const closeAnalyticsAndAdModal = useCallback(() => {
		setAnalyticsAndAdModalOpen(false);
	}, []);

	const closeOverModal = useCallback(() => {
		setOverModalOpen(undefined);
		setOverCallback(undefined);
	}, []);

	const openOverModal = useCallback((modalType, tab, sharedBy, cb) => {
		setOverModalOpen(modalType);
		setOverCallback(() => cb);
	}, []);

	const context = useMemo(() => ({
		activeTab,
		orderBundleType,
		closeOverModal,
		closeModal,
		modalOpen,
		openOverModal,
		openModal,
		setActiveTab,
		setModalOpen,
		setAnalyticsVod,
		setAnalyticsChannel,
		openAnalyticsAndAdModal,
		closeAnalyticsAndAdModal,
		setAdCampaignId,
		advertisingActiveTab,
	}), [
		activeTab,
		orderBundleType,
		closeOverModal,
		closeModal,
		modalOpen,
		openOverModal,
		openModal,
		setAnalyticsVod,
		setAnalyticsChannel,
		openAnalyticsAndAdModal,
		closeAnalyticsAndAdModal,
		setAdCampaignId,
		advertisingActiveTab,
	]);

	return (
		<UserSettingsContext.Provider value={context}>
			{children}
			<Suspense fallback={null}>
				{modalOpen === UserSettingsModal.ACCOUNT && (
					<MyAccountModal
						isOpen
						activeTab={activeTab}
						setActiveTab={setActiveTab}
					/>
				)}
				{modalOpen === UserSettingsModal.MEMBERSHIP && (
					<MembershipModal defaultTab={activeTab} isOpen />
				)}
				{modalOpen === UserSettingsModal.LIBRARY && (
					<LibraryModal defaultTab={activeTab} isOpen isSharedBy={isSharedBy} />
				)}
				{modalOpen === UserSettingsModal.INFO_CENTER && (
					<InfoCenterModal isOpen />
				)}
				{modalOpen === UserSettingsModal.SELECT_VIDEOS && (
					<LibraryModal
						handleSelectAsset={callback}
						defaultTab="videos"
						isOpen
					/>
				)}
				{modalOpen === UserSettingsModal.SELECT_IMAGES && (
					<LibraryModal
						handleSelectAsset={callback}
						defaultTab="images"
						isOpen
						initialValue={modalInitialValue}
						initialActiveMode={modalActiveMode}
						isSharedBy={modalInitialValue && modalActiveMode === LibraryModalMode.SHARED_BY_CHANNEL
							? isSharedBy : undefined}
					/>
				)}
				{modalOpen === UserSettingsModal.GFX_LIBRARY && (
					<LibraryModal
						handleSelectAsset={callback}
						defaultTab="gfx"
						isOpen
					/>
				)}
				{modalOpen === UserSettingsModal.VIDEO_IMAGE_LIBRARY && (
					<LibraryModal
						handleSelectAsset={callback}
						defaultTab="images"
						enabledTabs={['videos']}
						isOpen
					/>
				)}
				{modalOpen === UserSettingsModal.SURVEY_POLL && (
					<PaginationProvider initialItemsPerPage={10}>
						<ListSortingProvider>
							<SurveyVoteTemplateModal defaultTab={activeTab} isOpen />
						</ListSortingProvider>
					</PaginationProvider>
				)}
				{analyticsAndAdModalOpen && (
					<AnalyticsAndAdvertisingModal defaultTab={analyticsAndAdModalTab} isOpen />
				)}
				{modalOpen === UserSettingsModal.ANALYTICS_VOD_VIEW_MORE && (
					<AnalyticsEngagementOverviewVideoModal
						video={analyticsVod}
						isOpen
					/>
				)}
				{modalOpen === UserSettingsModal.ANALYTICS_CHANNEL_VIEW_MORE && !!analyticsChannel && (
					<AdminAnalyticsChannelOverviewModal
						channelId={analyticsChannel._id}
						isOpen
					/>
				)}
				{modalOpen === UserSettingsModal.AD_CAMPAIGN_VIEW_MORE && !!adCampaignId && (
					<AdminAdCampaignOverviewModal
						adCampaignId={adCampaignId}
						isOpen
					/>
				)}
				{overModalOpen === UserSettingsModal.KEY_LIBRARY && (
					<LibraryModal
						handleSelectAsset={overCallback}
						defaultTab="images"
						isOpen
					/>
				)}
			</Suspense>
		</UserSettingsContext.Provider>
	);
};

UserSettingsProvider.propTypes = {
	children: PropTypes.node,
};

UserSettingsProvider.defaultProps = {
	children: undefined,
};
