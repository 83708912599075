export const NotificationType = {
	DONATION: 'donation',
	PUBLIC_STUDIO_START_SOON: 'public_studio_start_soon',
	STUDIO_HOST_REMINDER: 'studio_host_reminder',
	STUDIO_IDLE: 'studio_idle',
	STUDIO_INVITE: 'invitation',
	INFORMATION: 'information',
	VIDEO: 'video',
	SPONSOR_AD: 'sponsor_ad',
	DONATION_THANK_YOU: 'donation_thank_you',
};
